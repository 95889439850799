<template>
  <div
    class="class1-explosives px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Tests for Class 1 (Explosives)" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Test Series 1: Is the substance a potential candidate for Class 1 (explosives)?"
        />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Gap Test (Zero Gap)
          </h2>
          <p>
            The sample is loaded into the specified steel tubes and placed
            facing a steel witness plate. A pentolite booster is used to provide
            a known shock source. The damage to the tube and witness plate is
            the criteria for a “go” or a “no-go” reaction.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Koenen Test
          </h2>
          <p>
            The Koenen test is used to measure the sensitiveness of solid and
            liquid substances to intense heat with varied confinement. The
            sample is placed into a specified sample tube. Flame is applied to
            engulf the sample tube at a known heating rate. The tube is heated
            for 5 minutes or until an earlier event occurs. The sample tube is
            examined to determine whether an explosion occurred.
          </p>
          <p>
            Varying the orifice plate over the top of the sample tube changes
            the degree of confinement of the sample. The orifice sizes are
            changed until an explosion effect occurs, or the substance passes
            the test with the smallest orifice. Three trials are conducted with
            the size of orifice plate one size larger than the level where any
            explosion occurred. This level is considered the limiting diameter.
            The substance is considered to have explosive properties if the
            limiting diameter is 1.0-mm or more.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Internal Ignition (10-g bag)
          </h2>
          <p>
            The test determines if a material will explode or detonate when
            ignited under confinement. The sample is loaded into a pipe with
            3000 pound pressure tested forged steel end caps. A 10-gram black
            powder bag igniter is inserted into the center of the pipe, the pipe
            is filled with test material and the ends capped. After the igniter
            is fired, if either the pipe or at least one of the end caps is
            fragmented into two or more distinct pieces then the test result is
            positive. The test is considered negative (the material passes) if
            the pipe is merely split open or the caps are sheared off in one
            piece. Three trials are performed unless a transition from
            deflagration to explosion occurs earlier.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Time Pressure Test
          </h2>
          <p>
            The Time/Pressure test measures the rate of reaction of a substance
            under confinement, and whether that reaction might lead to a
            deflagration. The test apparatus consists of an instrumented
            pressure vessel with a rupture disk. An electric match is connected
            to the electrodes on the firing plug. Black powder (50 mg) is
            usually added to assure initiation of the substance under test. For
            samples easily initiated with an electric match, the black powder
            was omitted. A 5-gram test sample is then placed in the pressure
            vessel. The pressure vessel is secured and the match is initiated.
            The pressure is recorded during the reaction. A time/pressure
            profile is obtained. Numerous explosives show a pressure rise from
            100 to 300 psi in less than 600 ms (milliseconds). A substance is
            definitely considered an explosive if the time interval for the
            pressure to rise from 100 to 300 psi is less than 30 ms in any one
            of three trials. The rupture disk provides relief to the pressure
            vessel above 300 psi.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="Test Series 2: Can the substance be excluded from Class 1?"
        />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Gap Test (50-mm Gap)
          </h2>
          <p>
            This test is used to measure the sensitivity of a substance, under
            confinement in a steel tube, to detonative shock. In this test, the
            sample is contained in a carbon steel tube with the bottom closed by
            two layers of polythene sheet pulled tightly. The booster charge
            consists of 160 g of RDX/wax (95/5) or PETN/TNT (50/50). The tube is
            placed in a vertical position and the PMMA spacer is placed in
            direct contact with the sheet to seal the bottom of the tube. After
            positioning the booster charge in contact with the PMMA spacer, the
            detonator is fixed in place against the bottom of the booster charge
            and initiated. Two tests are performed unless detonation of the
            substance is observed.<br />
            Reference: Transport of Dangerous Goods – Tests and Criteria
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Koenen Test
          </h2>
          <p>See explanation in Test Series 1</p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Internal Ignition (20-g bag)
          </h2>
          <p>
            This test is similar to the internal ignition test described earlier
            except that a 20-gram bag igniter is used instead of the 20-gram
            bag. As stated previously, either the pipe or at least one of the
            end caps must be fragmented into at least two distinct pieces for a
            positive result. Three trials are performed unless a transition from
            deflagration to explosion occurs earlier. The test determines if a
            material will explode or detonate when ignited under confinement.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Time Pressure Test
          </h2>
          <p>See explanation in Test Series 1</p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Test Series 3: Is the material a forbidden substance?"
        />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Impact Sensitivity
          </h2>
          <p>
            This test is used to determine whether a material is too sensitive
            for transport by conducting a screen test. The test is presented as
            a means used to obtain initial impact data for a manufacturing
            classification.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            ABL Friction Sensitivity
          </h2>
          <p>
            In the ABL Friction Test, the sample is placed on the anvil, and a
            known force is applied hydraulically through a stationary wheel. A
            pendulum or motor drive is used to propel the sliding anvil at any
            of several standard velocities perpendicular to the force vector.
            Sample initiation is detected by visual means (spark or flame).<br />
            Reference: The Transport of Dangerous Goods: Tests and Criteria,
            second edition, United Nations Test Method 3 (b) (iii) page 108.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Thermal Stability Test
          </h2>
          <p>
            This test is used to determine the reaction of samples when
            subjected to elevated temperatures for a determined period. The test
            uses an oven to verify handling, transportation, and storage
            requirements. The 50 g sample is placed in a constant temperature
            (explosion-proof) oven at 75 °C for a period of forty-eight hours.
            The temperature is continuously monitored and recorded. At the
            completion of the test, the sample is examined for discoloration,
            weight loss, and dimensional change for evidence of decomposition.
            This test is part of a series of tests used for establishing hazard
            classification.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Small-scale Burn Test
          </h2>
          <p>
            This test is used to determine if unconfined samples, once ignited
            continue burning or transit to an explosion or detonation. A bed of
            sawdust, containing small samples of test material, is ignited and
            monitored. The test is a “go” (positive) if explosion or detonation
            occurs.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="Test Series 4: Is the article a forbidden article?"
        />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Thermal Stability Test
          </h2>
          <p>See explanation in Test Series 3</p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Twelve Meter Drop Test
          </h2>
          <p>
            This test determines whether a test unit can withstand a free-fall
            impact without producing any significant fire or explosion hazard.
            The test unit is dropped from a height of 12m and examined to
            determine if any ignition or initiation occurred. Three drops are
            made on the packaged substance or article unless a fire or explosion
            occur earlier. However, each test unit is dropped only once.<br />
            Reference: The Transport of Dangerous Goods: Tests and Criteria,
            second edition, United Nations Test Method 3 (b) (I) page 101.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Test Series 5: Is the substance a candidate for 1.5?"
        />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            #8 Cap Test
          </h2>
          <p>
            The No. 8 cap test is used to determine susceptibility of explosives
            to detonation from the energy delivered by a No. 8 electric blasting
            cap. The test consists of a sample approximately 60 in3 in size
            placed in a cardboard tube (liquid samples are placed in a
            polypropylene bottle). The tube is placed on top of a steel witness
            plate with a No. 8 cap inserted at the top of the cardboard tube.
            Sample detonation is determined by examining the witness plate the
            criteria for detonation is that the witness plate is torn or
            penetrated.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Deflagration to Detonation Transition (DDT) Test
          </h2>
          <p>
            This test is used to determine the tendency of a substance to
            undergo transition from deflagration to detonation. In this test,
            the sample substance to be tested is contained in a carbon steel
            pipe, capped at one end with a “3000 pound” forged steel pipe cap,
            and at the other with a 13 cm square, 8 mm thick mild steel witness
            plate which is welded to the pipe. A 5.0 g black powder igniter,
            with a nickel-chromium resistance wire loop attached, is placed at
            the center of the vessel with the resistance wire loop attached to
            two insulated copper lead wires. These lead wires are fed through
            small holes in the wall of the pipe and sealed with an epoxy resin.
            The tube is placed in a vertical position and the igniter is fired
            by a 15 amperes current from a 20-volt transformer. Three trials are
            performed unless a deflagration to detonation transition occurs
            earlier. The test result is considered positive if a hole is punched
            through the witness plate.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            External Bonfire Test
          </h2>
          <p>
            This test is used to determine whether a substance, packaged as for
            transport, can explode if involved in a fire. A stack of packages is
            placed on a non-combustible surface (steel grate) above a lattice of
            dried wood soaked with diesel fuel or equivalent source. A wire
            basket or clamps may be used to hold the articles in place.
            Sufficient fuel is used to provide a 30-minute fire. The fire is
            ignited and the material is observed for: a) Evidence of detonation,
            deflagration or explosion of the total contents; b) Potentially
            hazardous fragmentation; and c) Thermal effects (i.e. size of the
            fireball, etc.).
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          title="Test Series 6: Is the substance or article a Class 1.1, 1.2, 1.3 or 1.4?"
        />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Single Package Test
          </h2>
          <p>
            This test determines if there is a mass explosion hazard of the
            contents of one package or unpackaged article. The package is placed
            on a steel witness plate on the ground, and confined to a depth of
            0.5 meters using containers that are similar in shape and size to
            the test package and completely filled with earth or sand (preferred
            method). Alternative methods of confinement are to use boxes or bags
            filled with earth or sand, or loose sand. The substance or article
            is initiated and the following observations are made: evidence of
            thermal effects, projection effects, detonation, deflagration or
            explosion of the total package contents. This test is performed
            three times unless a decisive result occurs earlier (e.g. explosion
            of the total contents). Evidence of a mass explosion includes a
            crater at the test site, damage to the witness plate, measurement of
            a blast, and disruption and scattering of the confining material.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            Stack Test
          </h2>
          <p>
            This test on a stack of packages or unpackaged articles determines
            whether an explosion is propagated from one package or unpackaged
            article to another. Sufficient packages or articles to give a total
            volume of 0.15 m³ with at least one acceptor are placed on a steel
            witness plate on the ground, and confined to a depth of 1 meter
            using containers that are similar in shape and size to the test
            package and completely filled with earth or sand (preferred method).
            Alternative methods of confinement are to use boxes or bags filled
            with earth or sand, or loose sand. The substance or article is
            initiated and the following observations are made: evidence of
            thermal effects, projection effects, detonation, deflagration or
            explosion of the total package contents. This test is performed
            three times unless a decisive result occurs earlier (e.g. explosion
            of the total contents). Evidence of a mass explosion includes a
            crater at the test site appreciably larger than that of a single
            unit, damage to the witness plate appreciably greater than that of a
            single unit, measurement of a blast significantly exceeding that of
            a single unit, and violent disruption and scattering of most of the
            confining material.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            External Fire Test
          </h2>
          <p>
            This test is performed on packages of an explosive substance or
            explosive articles, or unpackaged explosive articles, to determine
            whether there is a mass explosion or a hazard from dangerous
            projections, radiant heat and/or violent burning or any other
            dangerous effect when involved in a fire. A stack of substances or
            articles is placed on a non-combustible surface (steel grate) above
            a lattice of dried wood soaked with diesel fuel or equivalent
            source. Sufficient fuel is used to provide a 30-minute fire. The
            fire is ignited and the material is observed for: a) Evidence of
            detonation, deflagration or explosion of the total contents; b)
            Potentially hazardous fragmentation; and c) Thermal effects (i.e.
            size of the fireball, etc.).
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Class 1 Explosive Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "To determine what subclassification of Class 1 (explosive) and article or substance is, a series of tests must be performed. Tests include the Gap Test, Koenen Test, Internal Ignition, Time Pressure Test, sensitivity testing, Twelve Meter Drop Test, #8 cap test, DDT Test, External Bonfire Test, Stack Test, External Fire Test."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.class1-explosives {
  h2 {
    font-size: 18px;
    color: black;
    margin-bottom: 1.1em;
  }
}
</style>
